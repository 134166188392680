/* eslint-disable max-len */

import React, {useRef, useState} from 'react';
import Button from '../../components/Button';
import {post} from '../../helpers/api';
import {FEEDBACK_URL} from '../../helpers/endpoints';

const FeedbackPopup = (props) => {
  const inputFeedback = useRef(null);
  const [stars, setStars] = useState([0, 0, 0, 0, 0]);
  const starsCount = stars.filter(Boolean).length;

  const {onFeedbackDone, className, onCLickContainer, stationCode, email} = props;

  const onClickStar = (numberStar) => {
    const initStars = [0, 0, 0, 0, 0];
    for (let i=0; i< numberStar; i++) {
      initStars[i] = 1;
    }
    setStars([...initStars]);
  };
  const onFormSubmit = (event) => {
    event.preventDefault();
  };

  const onSave = async () => {
    const saveFeedbackRequest = {
      starsCount: starsCount,
      feedback: inputFeedback.current.value,
      waterStationId: stationCode.trim(),
      email: email,
    };

    // send saveFeedbackRequest
    const response = await post(FEEDBACK_URL, saveFeedbackRequest);
    console.log(response);
    onFeedbackDone();
  };

  const onCancel = () => {
    onFeedbackDone();
  };

  return (
    <div className={className} onClick={onCLickContainer}>
      <form onSubmit={onFormSubmit} className="feedback-popup__form d-flex
        flex-column px-4 align-items-center justify-content-center ta-center py-2">
        <h6>Rate Your Experience</h6>

        <div className="d-flex align-items-center">
          <span
            className={`feedback-popup__star fa ${stars[0] ? 'fa-star feedback-popup__star-checked' : 'fa-star-o'}`}
            onClick={() => onClickStar(1)}
          />
          <span
            className={`feedback-popup__star fa ${stars[1] ? 'fa-star feedback-popup__star-checked' : 'fa-star-o'}`}
            onClick={() => onClickStar(2)}
          />
          <span
            className={`feedback-popup__star fa ${stars[2] ? 'fa-star feedback-popup__star-checked' : 'fa-star-o'}`}
            onClick={() => onClickStar(3)}
          />
          <span
            className={`feedback-popup__star fa ${stars[3] ? 'fa-star feedback-popup__star-checked' : 'fa-star-o'}`}
            onClick={() => onClickStar(4)}
          />
          <span
            className={`feedback-popup__star fa ${stars[4] ? 'fa-star feedback-popup__star-checked' : 'fa-star-o'}`}
            onClick={() => onClickStar(5)}
          />
        </div>
        <h6 className='mt-2 align-self-start'>Tell us more</h6>

        <input ref={inputFeedback} className="w-100 feedback-popup__text px-1" type="text" id="feedbackInput"/>
        <div className='d-flex'>
          <Button
            onClick={onCancel}
            className={`justify-content-center mx-1 d-flex mt-2`}
            text="Cancel" buttonClassName="button-disabled" />
          <Button
            onClick={onSave}
            className={`justify-content-center mx-1 d-flex mt-2`}
            text="Submit" buttonClassName="button-secondary" />
        </div>
      </form>

    </div>
  );
};

export default FeedbackPopup;
